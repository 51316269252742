import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from "../components/seo"

const EULA = (props) => {
  const siteTitle = props.data.site.siteMetadata.title;
  const siteSlogan = props.data.site.siteMetadata.slogan;
  const frontmatter = {
    slug: props.location.pathname.replace(/[^\w\d-]/g, '')
  };

  return (
   <Layout title={siteTitle} slogan={siteSlogan}>
      <SEO
        title="End User License Agreement"
        description="Terms and definitions of the End User License Agreement"
        frontmatter={frontmatter}
      />
     <main>
      <h1 className="blog-heading">End User License Agreement</h1>
      <h2>Terms and definitions</h2>
      <p><strong>Online Store</strong> – the site, which is located at powerwatcher.net, where every buyer can get acquainted with the presentation of the goods, their description, prices, select a specific product, the method of delivery of goods and payment, using payment system installed on the site.</p>
      <p><strong>The buyer</strong> – a legal or individual person specified in the order as the recipient of the goods, which places an order in the online shop: goods, acquires and receives goods according to the chosen method of delivery.</p>
      <p><strong>Registered buyers</strong> – Buyer, the Seller has granted about himself individual information (surname, first name, email address, phone number), which can be used for ordering multiple times (this information is opening when ordering).</p>
      <p><strong>Seller</strong> – sole trader engaged in the sale of goods in the shop powerwatcher.net. According to this Regulation, the Seller is the sole trader Yuriy Logvin.</p>
      <p><strong>Phone</strong>: +38 067 573 77 22, e-mail: Yuriy.Logvin(at)gmail.com</p>
      <p><strong>Product (service)</strong> – the object of the sale, not withdrawn and not limited in the civil circulation and for sale in the shop by placing in the relevant section online store.</p>
      <p><strong>Order</strong> – properly executed application submitted by the Buyer to purchase on the website of the store goods, sent through the site, e-mail or a message to the Seller by telephone.</p>
      <p><strong>Courier delivery service</strong> – a legal entity and / or individual entrepreneurs providing services to the Seller for the delivery of customer orders.</p>
      <h2>General provisions</h2>
      <p>These “Rules of internet-shop powerwatcher.net (hereinafter – the” Regulations “) govern the retail sale of goods through the online store.</p>
      <p>Each Party shall ensure that the other Party, which has the necessary legal capacity, as well as all the rights and powers necessary and sufficient for the conclusion and execution of the contract of retail sale.</p>
      <p>When ordering goods through the online store, the buyer agrees to the rules set out below.</p>
      <p>Seller reserves the right to change these Terms, in this connection, the buyer shall regularly monitor changes in the Rules posted on the Internet page of the store.</p>
      <p>Buyer agrees with these rules when ordering, or when dialing the appropriate telephone number (when ordering over the phone).</p>
      <p>The current version of the Rules is available at online store.</p>
     </main>
   </Layout>
  )
}

export default EULA

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        slogan
      }
    }
  }
`